import { inject, Injectable } from '@angular/core';
import { UsersChurchApi } from '@ministrary/shared/apis/users-church.api';
import { PERMISSIONS } from '@ministrary/shared/constants/permissions.constant';
import { eAction } from '@ministrary/shared/enums/action.enum';
import { eGroupMemberType } from '@ministrary/shared/enums/group-member-type.enum';
import { eResource } from '@ministrary/shared/enums/resources.enum';
import { eRoles } from '@ministrary/shared/enums/roles.enum';
import { eUserRoles } from '@ministrary/shared/enums/user-roles.enum';
import { eMinistryType } from '@ministrary/shared/interfaces/ministry/ministry.interface';
import { iUser } from '@ministrary/shared/interfaces/user.interface';

import { AuthService } from '../auth/auth.service';
import { ChurchService } from '../church/church.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private usersChurchApi = inject(UsersChurchApi);
  private churchService = inject(ChurchService);
  public authService = inject(AuthService);

  userRoles: (eUserRoles | eRoles)[] = [];

  async load() {
    const church_id = this.churchService.churchId!;
    const user_id = this.authService.currentUser.id;
    const churchUser = await this.usersChurchApi.loadUserRoles(user_id, church_id);
    if (churchUser.users) this.updateUserRoles(churchUser.users);

    const sameRoles = this.userRoles?.every(role => churchUser.user_roles?.includes(role));
    if (!sameRoles) {
      await this.usersChurchApi.update({ user_roles: this.userRoles }).match({ user_id, church_id });
    }
  }

  hasRoles(roles: (eRoles | eUserRoles)[]) {
    if (!this.userRoles) return false;

    for (const role of roles) {
      if (this.userRoles.includes(role as eUserRoles | eRoles)) return true;
    }

    return false;
  }

  hasPermission(resource: eResource, action: eAction) {
    if (!this.userRoles) return false;

    const roles = PERMISSIONS[resource]?.[action];
    if (!roles) return false;

    for (const role of roles) {
      if (this.userRoles.includes(role)) return true;
    }

    return false;
  }

  private updateUserRoles(user: iUser) {
    const isCoordinator = !!user.group_network_coordinator?.length;
    const isSupervisor = !!user.group_network_supervisor?.length;
    const isLeader = !!user.group_members?.some(groupMember => groupMember.member_type === eGroupMemberType.LEADER);
    const isViewer = !!user.group_members?.length;

    const isVolunteer = !!user.ministry_user?.length;
    const isMinistryLeader = !!user.ministry_user?.some(ministryUser => ministryUser.is_leader);
    const isScheduler = !!user.ministry_user?.some(ministryUser => ministryUser.is_scheduler);
    const canControlLiveEvents = !!user.ministry_user?.some(ministryUser => ministryUser.can_control_live_events);

    const isKidsVolunteer = !!user.ministry_user?.some(ministryUser => ministryUser?.ministry?.is_child_ministry || ministryUser?.ministry?.type === eMinistryType.KIDS);
    const isKidsLeader = !!user.ministry_user?.some(ministryUser => ministryUser.is_leader && (ministryUser?.ministry?.is_child_ministry || ministryUser?.ministry?.type === eMinistryType.KIDS));

    const isWorshipLeader = !!user.ministry_user?.some(ministryUser => ministryUser.is_leader && ministryUser?.ministry?.type === eMinistryType.WORSHIP);

    const canManageCalendar = !!user.ministry_user?.some(ministryUser => ministryUser.can_manage_calendar);

    const rolesMap: { [key: string]: boolean } = {
      [eUserRoles.SCHEDULER_VIEWER]: isVolunteer,
      [eUserRoles.SCHEDULER_EDITOR]: isScheduler,
      [eUserRoles.SCHEDULER_MANAGER]: isMinistryLeader,
      [eUserRoles.LIVE_EVENTS_MANAGER]: canControlLiveEvents,

      [eUserRoles.KIDS_VIEWER]: true,
      [eUserRoles.KIDS_EDITOR]: isKidsVolunteer,
      [eUserRoles.KIDS_MANAGER]: isKidsLeader,

      [eUserRoles.CALENDAR_VIEWER]: isMinistryLeader,
      [eUserRoles.CALENDAR_EDITOR]: isMinistryLeader || canManageCalendar,

      [eUserRoles.GROUP_COORDINATOR]: isCoordinator,
      [eUserRoles.GROUP_MANAGER]: isSupervisor,
      [eUserRoles.GROUP_EDITOR]: isLeader,
      [eUserRoles.GROUP_VIEWER]: isViewer,

      [eUserRoles.WORSHIP_VIEWER]: isWorshipLeader,
      [eUserRoles.WORSHIP_EDITOR]: isWorshipLeader,
      [eUserRoles.WORSHIP_MANAGER]: isWorshipLeader
    };

    this.userRoles = Object.keys(rolesMap).filter(role => rolesMap[role]) as eUserRoles[];
    if (user.roles) this.userRoles = [...this.userRoles, ...user.roles];
  }
}
