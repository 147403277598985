import { Base } from '@ministrary/shared/interfaces/base.interface';
import { iUser } from '@ministrary/shared/interfaces/user.interface';

import { iMinistryFormField } from './ministry-form-field.interface';
import { iMinistryRole } from './ministry-role.interface';
import { iMinistryUser } from './ministry-user.interface';

export interface iMinistry extends Base {
  name: string;
  is_child_ministry: boolean;

  ministry_user?: iMinistryUser[];
  ministry_role?: iMinistryRole[];
  ministry_form_field?: iMinistryFormField[];
  leader?: iUser[];

  type?: eMinistryType;
}

export enum eMinistryType {
  KIDS = 'kids',
  WORSHIP = 'worship'
}
